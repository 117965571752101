.spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: conic-gradient(#0000 10%,#ffffff);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
    animation: spinner-zp9dbg 1s  infinite linear;
 }
 .loader {
    width: 100%;
    height: 100vh;
    background: linear-gradient(90deg, #221F4A 19.37%, #A52E62 133.17%);
    background: rgb(0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 @keyframes spinner-zp9dbg {
    to {
       transform: rotate(1turn);
    }
 }