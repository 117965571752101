.privacy-policy {
    margin: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .privacy-policy h1 {
    color: #333;
    text-align: center;
  }
  
  .privacy-policy h2 {
    color: #555;
    margin-top: 20px;
  }
  
  .privacy-policy h3 {
    color: #777;
    margin-top: 15px;
  }
  
  .privacy-policy p {
    margin-bottom: 15px;
  }
  
  .privacy-policy ul {
    margin-bottom: 15px;
    padding-left: 20px;
  }
  
  .privacy-policy li {
    margin-bottom: 10px;
  }
  

  .privacy-view header {
    background: linear-gradient(90deg, #221F4A 19.37%, #A52E62 133.17%);
}


