* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.container {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1140px;
    }
}
@media (min-width: 1400px) {
    .container {
        width: 1320px;
    }
}
header {
    width: 100%;
    padding: 20px 0;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.08) 89.17%);
    backdrop-filter: blur(17px);
    z-index: 20;
}

header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header .container >a >img {
    height: 64px;
}

header .container .options {
    display: flex;
    align-items: center;
    gap: 18px;
}
header .container .options button {
    background-color: transparent;
    border: none;
    position: relative;
}
header .container .options button img {
    width: 40px;
}
header .container .options .lang_btn {
    font-family: Lama Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 15.89px;
    text-align: center;
    padding: 8px 16px;
    background: #FFFFFF;
    border-radius: 2px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero {
    position: relative;
    width: 100%;
    min-height: 100vh;
}

.hero >img, .hero >video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(95%);
}

.hero::after {
    content: "";
    background: linear-gradient(180deg, rgba(6, 6, 6, 0) 42.55%, #060606 93.65%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hero .container {
    position: relative;
    z-index: 10;
    min-height: 100vh;
    padding-top: 200px;
    flex-direction: column;
    display: flex;
    justify-content: end;
    align-items: start;
    padding: 200px 40px  0;
}

.hero .container span {
    
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    line-height: 30px;
    
    color: #FFFFFF;
    display: block;
    margin-bottom: 8px;
}

.hero .container h1 {
    color: #FFFFFF;
    text-transform: uppercase;
    font-family: Agharti;
    font-size: 120px;
    font-weight: 700;
    line-height: 120px;
    
    display: block;
    margin-bottom: 40px;
}

.hero a {
    
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.04em;
    
    color: #FFFFFF;
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 8px;
}

.hero a svg {
    width: 24px;
    height: 24px;
    stroke: #FFFFFF;
}

.hero a:not(.discover_more) svg {
    padding: 4px;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
}

.hero .discover_more {
    width: 100%;
    margin: 56px 0;
    justify-content: center;
}

@media (max-width: 1399.98px) {
    header .container > img {
        height: 56px;
    }
    .hero .container h1 {
        font-size: 110px;
        line-height: 106px;
        margin-bottom: 32px;
    }
    .hero .discover_more {
        margin: 40px 0;
    }
}

@media (max-width: 1199.98px) {
    header .container > img {
        height: 48px;
    }
    .hero .container span {
        font-size: 18px;
        line-height: 10px;
        margin-bottom: 8px;
    }
    .hero .container h1 {
        font-size: 94px;
        line-height: 93px;
    }
    .hero a {
        font-size: 16px;
    }
    .hero a svg {
        width: 22px;
        height: 22px;
    }
    .hero a:not(.discover_more) svg {
        padding: 4px;
        border: 1px solid #FFF;
    }
}

@media (max-width: 992.98px) {
    .hero .container h1 {
        font-size: 86px;
        line-height: 84px;
        margin-bottom: 24px;
    }
}

@media (max-width: 575.98px) {
    .hero .container h1 {
        font-size: 56px;
        line-height: 56px;
    }
    .hero .container span {
        font-size: 16px;
        line-height: 10px;
        margin-bottom: 8px;
    }
    .hero a {
        font-size: 13px;
    }
    .hero a:not(.discover_more) svg {
        padding: 0;
        border: none;
    }
    .hero .discover_more {
        margin: 56px 0 32px;
    }
    .hero a svg {
        width: 20px;
        height: 20px;
    }
    header .container .options .lang_btn {
        font-family: Lama Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 15.89px;
        text-align: center;
        padding: 6px 11px;
        background: #FFFFFF;
        border-radius: 2px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    header .container .options button img {
        width: 30px;
    }
    header .container > a > img {
        height: 40px;
    }
    header {
        padding: 12px 0;
    }
    header .container .options {
        gap: 12px;
    }
}
/* starts music events */
.music_events {
    background: linear-gradient(90deg, #221F4A 19.37%, #A52E62 133.17%);
    overflow: hidden;
}

.music_events .swiper {
    width: 100%;
    height: 100%;
    height: 450px;
}

.music_events .container.events_wrapper {
    display: grid;
    grid-template-columns: 2fr max-content;
    gap: 56px;
    padding: 100px 16px;
}

.music_events .container .swiper .swiper-slide {
    direction: ltr;
    height: 100%;
    background-color: white;
    opacity: .5;
    transition: .4s ease-in all !important;
    position: relative;
}
.music_events .container .swiper .swiper-slide >a {
    position: relative;
    height: 100%;
    display: block;
}
.music_events .container .swiper .swiper-slide >a::after {
    content: "";
    background: linear-gradient(180deg, rgba(6, 6, 6, 0) 42.55%, #060606 93.65%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.music_events .container .swiper .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.music_events .container .swiper .swiper-slide .text {
    position: absolute;
    opacity: 0;
    bottom: 16px;
    left: 16px;
    color: #FFFFFF;
    z-index: 3;
}
.music_events .container .swiper .swiper-slide .text a{
    color: #fff;
    text-decoration: none;
}

.music_events .container .swiper .swiper-slide.swiper-slide-active .text {
    
    opacity: 1;
}
.music_events .container .swiper .swiper-slide.swiper-slide-active .text p {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.04em;
        
}
.music_events .container .swiper .swiper-slide.swiper-slide-active .text span {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.04em;
    
}
.music_events .container .swiper .swiper-slide.swiper-slide-active {
    min-width: 350px;
    opacity: 1;
    border: 25px solid #E6F600;
    box-sizing: border-box;
}
.music_events .text_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.music_events .text_wrapper span {
    
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.04em;
    
    display: block;
    margin-bottom: 16px;
    color: #FFF;
}

.music_events .text_wrapper h1 {
    
    font-size: 48px;
    font-weight: 800;
    line-height: 57.6px;
    letter-spacing: 0.02em;
    margin-bottom: 16px;
    
    color: #FFF;
}


.music_events .text_wrapper p {
    
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.04em;
    
    color: #FFF;
}


.music_events .text_wrapper .navigation {
    display: flex;
    gap: 24px;
}

.music_events .text_wrapper .navigation button:first-child {
    background: #221F4A;
}

.music_events .text_wrapper .navigation button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 58px;
    height: 58px;
    background: #2956F3;
    transition: all .2s ease-in;
}

.music_events .text_wrapper .navigation button:hover {
    opacity: .85;
}
.music_events .text_wrapper .navigation button svg {
    stroke: #FFF;
}

.top_sposor_wrapper  h1{
    margin-bottom: 56px;
    
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    text-align: center;
    color: #FFF;
    text-transform: uppercase;
}
.top_sposor_wrapper {
    padding: 20px 16px 80px;
}
.top_sposor_wrapper img {
    max-width: 155px;
}

.top_sposor_wrapper .sponsors {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 56px;
}
.top_sposor_wrapper .sponsors a{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 0;
}

@media (max-width: 1399.98px) {
    .music_events .text_wrapper span {
        font-size: 20px;
    }
    .music_events .text_wrapper h1 {
        font-size: 43px;
        font-weight: 800;
        line-height: 50.6px;
        margin-bottom: 16px;
    }
    .music_events .text_wrapper p {
        font-size: 19px;
        font-weight: 400;
        line-height: 30px;
    }
    .music_events .text_wrapper .navigation button {
        width: 52px;
        height: 52px;
    }
    .music_events .container.events_wrapper {
        padding: 80px 16px;
    }
    .top_sposor_wrapper h1 {
        margin-bottom: 56px;
        font-size: 42px;
        line-height: 50.6px;
    }
    .top_sposor_wrapper .sponsors {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(195px, 1fr));
    }
}

@media (max-width: 1199.98px) {
    .music_events .container .swiper .swiper-slide.swiper-slide-active {
        min-width: 290px;
        border: 20px solid #E6F600;
    }
    .music_events .swiper {
        height: 390px;
    }
    .top_sposor_wrapper .sponsors a {
        padding: 14px 0;
    }
    .top_sposor_wrapper h1 {
        margin-bottom: 46px;
        font-size: 37px;
        line-height: 40.6px;
    }
    .top_sposor_wrapper .sponsors {
        grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
    }
}
@media (max-width: 992.98px) {
    .top_sposor_wrapper .sponsors {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 32px;
        flex-wrap: wrap;
    }
    .top_sposor_wrapper .sponsors a:not(:last-child) {
        border: none;
    }
    .top_sposor_wrapper h1 {
        margin-bottom: 40px;
        font-size: 32px;
        line-height: 30.6px;
    }
}
@media (max-width: 767.98px) {
    .music_events .container.events_wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: 56px;
        padding: 56px 16px;
        display: flex;
        flex-direction: column-reverse;
        gap: 16px;
    }
    .music_events .container .swiper .swiper-slide img {
        object-position: center top;
    }
    .music_events .text_wrapper span {
        font-size: 18px;
    }
    .music_events .text_wrapper h1 br:last-child{
        display: none;
    }
    .music_events .text_wrapper h1 {
        font-size: 25px;
        font-weight: 700;
        line-height: 30.6px;
        margin-bottom: 0;
    }
    .music_events .text_wrapper p {
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
    }
    .music_events .text_wrapper p br {
        display: none;
    }
    .music_events .text_wrapper .navigation {
        display: flex;
        gap: 16px;
        justify-content: end;
        margin-top: 16px;
    }
    .music_events .text_wrapper .navigation button {
        width: 32px;
        height: 32px;
    }
    .music_events .container .swiper .swiper-slide.swiper-slide-active {
        min-width: 290px;
        border: 10px solid #E6F600;
    }
    .top_sposor_wrapper {
        padding: 16px 16px 40px;
    }    
}

@media (max-width: 575.98px) {
    .top_sposor_wrapper h1 {
        margin-bottom: 40px;
        font-size: 25px;
        line-height: 30.6px;
    }
    .top_sposor_wrapper img {
        max-width: 75px;
    }
    .top_sposor_wrapper .sponsors {
        gap: 16px;
    }
}

/* categories  */
.categories {
    padding: 100px 0;
    background: #080114;
    position: relative;
    overflow: hidden;
}   
/*
.categories::before {
content: "";
position: absolute;
width: 860px;
height: 2400px;
top: -948px;

background: #08B7BF;
opacity: 0.2;
transform: rotate(45deg);

}

.categories::after {
content: "";
position: absolute;
width: 860px;
height: 2400px;
left: 16px;
top: -648px;

background: #5117E0;
opacity: 0.2;
transform: rotate(45deg);

}
*/

.categories .container {
    z-index: 16;
    position: relative;
}

.categories .container >h1 {
    
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    letter-spacing: -0.01em;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 16px;
    text-transform: uppercase;
}

.categories .container >p {
    /* Li Europan lingues es membres del sam familie. Lor separat existentie es un myth. Por scientie, musica, sport etc, litot Europa usa li sam vocabular. */

max-width: 703px;

font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
text-align: center;
color: #FFFFFF;
display: flex;
margin: auto;

}

.categories .categories_wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 110px;
    margin-top: 80px;
}

.categories_wrapper >div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    
    text-align: center;
    position: relative;
    transition: all .2s ease-in;
    cursor: pointer;
}
.categories_wrapper .card_wrapper {
    z-index: 16;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
}
.categories_wrapper h2 {
    color: #fff;
    text-transform: uppercase;
    z-index: 2;
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    text-align: center;

}
.categories_wrapper p {
    color: #221F4A;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
    z-index: 2;
}
.categories_wrapper >div svg *:not([stroke]) {
    fill: #221f4a;
}
.categories_wrapper >div svg *:not([fill]) {
    stroke: #221f4a;
}
.events_schedual .container .content .body .card .text .category svg *:not([stroke]) {
    fill: #FFD86F;
}
.events_schedual .container .content .body .card .text .category svg *:not([fill]) {
    stroke: #FFD86F;
}
.categories_wrapper >div svg, .categories >div img {
    width: 105px;
    z-index: 2;
    margin-bottom: 24px;
}
.categories_wrapper .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.categories .categories_wrapper a {
    text-decoration: none;
}
.categories .categories_wrapper >div:hover {
    transform: translateY(-16px);
}

@media (max-width: 1399.98px) {
    .categories .container > h1 {
        font-size: 40px;
        line-height: 49.6px;
    }
    .categories .categories_wrapper {
        grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
        margin-top: 72px;
    }
    .categories_wrapper > div svg {
        margin-bottom: 32px;
    }
    .categories_wrapper h2 {
        font-size: 26px;
    }
    .categories_wrapper p {
        font-size: 13px;
    }
}

@media (max-width: 992.98px) {
    .categories .container > h1 {
        font-size: 32px;
        line-height: 29.6px;
    }
    .categories .container > p {
        max-width: 613px;
        font-size: 16px;
    }
    .categories .categories_wrapper {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        margin-top: 64px;
        gap: 64px;
    }
}

@media (max-width: 767.98px) {
    .categories .categories_wrapper {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        margin-top: 64px;
        gap: 24px;
    }
    .categories_wrapper > div svg {
        margin-bottom: 24px;
    }
    .categories_wrapper h2 {
        font-size: 23px;
    }
}

@media (max-width: 575.98px) {
    .categories {
        padding: 40px 0;
    }
    .categories .container > h1 {
        font-size: 24px;
        line-height: 19.6px;
    }
    .categories .container > p {
        max-width: 613px;
        font-size: 13px;
        line-height: 20px;
    }
    .categories .categories_wrapper {
        margin-top: 32px;
    }
    .categories .categories_wrapper {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        margin-top: 64px;
        gap: 24px;
    }
    .categories_wrapper > div svg, .categories > div img {
        width: 55px;
        z-index: 2;
        margin-bottom: 16px;
    }
    .categories_wrapper h2 {
        font-size: 16px;
        line-height: 23px;
    }
}

.events_schedual {
    padding: 100px 0;
    background: linear-gradient(90deg, #E6F600 -30.02%, #31BA99 27.95%, #4C13A2 184.7%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.events_schedual .container {
    display: flex;
    justify-content: space-between;
    gap: 100px;
}

.events_schedual .container >.content {
    width: 100%;
}
.events_schedual .container >.text span {
    
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.04em;
    display: block;
    margin-bottom: 8px;
    color: #FFD86F;
}

.events_schedual .container .text h1 {
    
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: 0.02em;
    margin-bottom: 16px;
    
    color: #FFFFFF;
}

.events_schedual .container >.text p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    
    width: 400px;
    color: #FFFFFF;
    margin-bottom: 32px;
}

.events_schedual .container >.text a {
    transition: all .2s ease-in;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
    gap: 10px;
    background: rgba(255, 255, 255, 0.08);
    border: 2px solid rgba(255, 255, 255, 0.08);
    border-radius: 2px;
    font-size: 16px;
    width: max-content;
    text-decoration: none;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.04em;
    
    color: #FFF;    
}
.events_schedual .container >.text a:hover {
    border: 2px solid white;
}

.events_schedual .container .content .head {
    display: flex;
    gap: 16px;
}
.events_schedual .container .content .head button {
    width: 100%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    background-color: transparent;
    border: none;
    
    color: white;
}
.events_schedual .container .content .head button span {
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    margin-bottom: 8px;
}
.events_schedual .container .content .head button h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    
    display: flex;
    gap: 8px;
    align-items: end;
}
.events_schedual .container .content .head button h2 span {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    
}

.events_schedual .container .content .line {
    width: 100%;
    height: 4px;
    background: #FFFFFF33;
    margin: 24px 0 40px;
    position: relative;
}

.events_schedual .container .content .line span {
    width: 33%;
    height: 100%;
    background: #ffffff;
    display: block;
    position: absolute;
    top: 0;
    transition: all .2s ease-in-out;
    left: 0;
}

.events_schedual .container .content .body {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.events_schedual .container .content .body img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 3px;
}

.events_schedual .container .content .body .card {
    display: flex;
    text-decoration: none;
    gap: 32px;
}

.events_schedual .container .content .body .card >.text {
    display: grid;
    grid-template-rows: max-content 1fr max-content;
}
.events_schedual .container .content .body .card .text h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    
    margin-bottom: 8px;
    color: #fff;
}

.events_schedual .container .content .body .card >.text p {
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: 32px;
    
    color: #fff;
}
.events_schedual .container .content .body .card .text .category{
    display: flex;
    align-items: start;
    gap: 12px;
}

.events_schedual .container .content .body .card .text .category svg{
    width: 42px;
    object-fit: contain;
    height: auto;
}
.events_schedual .container .content .body .card .category p,
.events_schedual .container .content .body .card .category span {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;
    
    color: white;
    margin-bottom: 0;
}
.events_schedual .container .content .body .card .category span {
    opacity: .6;
}
@media (max-width: 1199.98px) {
    .events_schedual .container > .text p {
        font-size: 17px;
        line-height: 30px;
        width: 280px;
    }
    .events_schedual .container .text h1 {
        font-size: 43px;
        line-height: 50px;
    }
    .events_schedual .container > .text span {
        font-size: 18px;
    }
    .events_schedual .container .content .head button span {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 8px;
    }
    .events_schedual .container .content .head button h2 {
        font-size: 26px;
        line-height: 42px;
        gap: 8px;
    }
    .events_schedual .container .content .head button h2 span {
        font-size: 13px;
        line-height: 24px;
    }
    .events_schedual .container .content .body .card > .text p {
        font-size: 14px;
        line-height: 25px;
    }
    .events_schedual .container .content .body img {
        width: 180px;
        height: 180px;
    }
}

@media (max-width: 992.98px) {
    .events_schedual .container {
        flex-direction: column;
        gap: 40px;
    }
    .events_schedual .container > .text span {
        font-size: 18px;
    }
    .events_schedual .container .text h1 br {
        display: none;
    }
    .events_schedual .container .text h1 {
        font-size: 43px;
        line-height: 50px;
        margin-bottom: 4px;
    }
    .events_schedual .container > .text p {
        font-size: 15px;
        line-height: 30px;
        width: 100%;
        margin-bottom: 8px;
    }
    .events_schedual .container >.text a {
        padding: 12px 40px;
    }
}
span.mobile {
    display: none;
}
@media (max-width: 575.98px) {
    .events_schedual .container .content .head button h2 span {
        display: none;
    }
    span.mobile {
        display: block !important;
    }
    .events_schedual .container .content .head button span {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 2px;
    }
    .events_schedual .container .content .head button h2 span {
        font-size: 11px;
        font-weight: 400;
    }
    .events_schedual .container .content .head button h2 {
        font-size: 19px;
        line-height: 32px;
        gap: 8px;
    }
    .events_schedual {
        padding: 40px 0;
    }
    .events_schedual .container > .text span {
        font-size: 16px;
        margin: 0;
    }
    .events_schedual .container .text h1 {
        font-size: 25px;
        line-height: 40px;
        margin-bottom: 4px;
    }
    .events_schedual .container > .text p {
        font-size: 14px;
        line-height: 22px;
        width: 100%;
        margin-bottom: 11px;
    }
    .events_schedual .container .text a {
        padding: 3px 0px;
        font-size: 14px;
      }
    .events_schedual .container .content .body .card {
        display: flex;
        gap: 8px;
        flex-direction: column;
    }
    .events_schedual .container .content .body img {
        width: 100%;
        height: 180px;
        object-position: center 20%;
    }
    .events_schedual .container .content .body .card > .text {
        padding: 0 8px;
    }
    .events_schedual .container .content .body .card .text h2 {
        font-size: 21px;
        font-weight: 700;
        line-height: 32px;
        
        margin-bottom: 4px;
        color: #fff;
    }
    .events_schedual .container .content .body .card > .text p {
        font-size: 13px;
        line-height: 22px;
        margin-bottom: 16px;
    }
    .events_schedual .container .content .body .card .text .category img {
        width: 32px;
        object-fit: contain;
        height: auto;
    }
    .events_schedual .container .content .body .card .category p, .events_schedual .container .content .body .card .category span {
        margin-bottom: 0;
        line-height: 16px;
        font-size: 13px;
    }
}

.restaurants {
    padding: 100px 0;
    background: linear-gradient(90deg, #7CE9F0 -30.02%, #485FEB 64.46%);
}

.restaurants .container >span {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.04em;
    text-align: center;
    display: block;
    color: white;
}

.restaurants .container >h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    letter-spacing: 0.02em;
    text-align: center;
    color: white;
    margin-bottom: 64px;
}

.restaurants .container .restaurants_wrapper img,
.restaurants .container .restaurants_wrapper img .title {
    transition: all .3s ease-in-out;
}

.restaurants .container .restaurants_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: min-content !important;
}

.restaurants .container .restaurants_wrapper .card >div a{
    display: flex !important;
    flex-direction: column;
    justify-content: start;
    text-decoration: none;
    align-items: center;
    gap: 32px;
}
.restaurants .container .restaurants_wrapper .card{
    padding: 40px;
    width: 100%;
    height: 550px;
}
.restaurants .container .restaurants_wrapper .card:last-child{
    background: #ffffff0d;
}

.restaurants .container .restaurants_wrapper .card:nth-child(3){
    background: #ffffff1a;
}

.restaurants .container .restaurants_wrapper .card:nth-child(2){
    background: #ffffff31;
}

.restaurants .container .restaurants_wrapper .card:nth-child(1){
    background: #ffffff3f;
}

.restaurants .container .restaurants_wrapper .card img{
    width: 64px;
    height: 64px;
    object-fit: cover;
}

.restaurants .container .restaurants_wrapper .card p,
.restaurants .container .restaurants_wrapper .card a,
.restaurants .container .restaurants_wrapper .card h3{
    display: none;
}

.restaurants .container .restaurants_wrapper .card .title{
    transform: rotate(90deg) translatex(40px);
    white-space: nowrap;
}
.restaurants .container .restaurants_wrapper .card h2{
    color: white;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.04em;
    
}

.restaurants .container .restaurants_wrapper .card.open p,
.restaurants .container .restaurants_wrapper .card.open a,
.restaurants .container .restaurants_wrapper .card.open h3{
    display: block;
}

.restaurants .open {
    background-color: #485FEB !important;
    width: max-content;
}

.restaurants .container .restaurants_wrapper .card.open img {
    width: 450px;
    height: 260px;
}

.restaurants .container .restaurants_wrapper .card.open .title{
    transform: none;
}

.restaurants .container .restaurants_wrapper .card.open >div a{
    gap: 18px;
    flex-direction: row;
    align-items: start;
}

.restaurants .container .restaurants_wrapper .card.open .title {
    font-size: 20px;
    margin-bottom: 8px;
}
.restaurants .container .restaurants_wrapper .card.open .title h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.04em;
    
    color: #FFFFFFA3;
}
.restaurants .container .restaurants_wrapper .card.open p {
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    
    color: white;
    margin-top: 16px;
}
.restaurants .container .restaurants_wrapper .card.open >a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 10px;

    background: #FFFFFF;
    border-radius: 2px;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-decoration: none;
    width: max-content;
    margin-top: 24px;
    float: right;
    color: #5E78EE;

}
.restaurants .container .restaurants_wrapper .card >a,
.restaurants .container .restaurants_wrapper .card p {
    opacity: 0;
}

/* State when the open class is added */
.restaurants .container .restaurants_wrapper .card.open a,
.restaurants .container .restaurants_wrapper .card.open p {
    animation: fadeIn 0.2s ease-in 0.2s forwards;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 1399.98px) {
    .restaurants .container .restaurants_wrapper .card:not(.open) {
        width: calc(80px + 64px);
    }
}

@media (max-width: 1199.98px) {
    .restaurants .container .restaurants_wrapper .card:not(.open) {
        width: calc(64px + 64px);
    }
    .restaurants .container .restaurants_wrapper .card {
        padding: 32px;
        width: 100%;
        height: 490px;
    }
    .restaurants .container .restaurants_wrapper .card.open img {
        width: 270px;
        height: 210px;
    }
    .restaurants .container .restaurants_wrapper .card.open p {
        font-size: 15px;
        font-weight: 400;
        line-height: 26px;
        
        color: white;
        margin-top: 16px;
    }
    .restaurants .container > h1 {
        font-size: 40px;
        margin-bottom: 40px;
    }
}

@media (max-width: 992.98px) {
    .restaurants .container .restaurants_wrapper .card {
        padding: 32px;
        width: 100%;
        height: 570px;
    }
    .restaurants .container .restaurants_wrapper .card.open img {
        width: 100%;
        height: 190px;
    }
    .restaurants .container .restaurants_wrapper .card.open p {
        font-size: 13px;
        font-weight: 400;
        line-height: 23px;
        
        color: white;
        margin-top: 8px;
    }
    .restaurants .container .restaurants_wrapper .card.open > div a{
        flex-direction: column;
    }
    .restaurants .container > h1 {
        font-size: 30px;
        margin-bottom: 30px;
        line-height: 43px;
    }
}

@media (max-width: 767.98px) {
    .restaurants .container .restaurants_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: min-content !important;
        flex-direction: column;
    }
    .restaurants .container .restaurants_wrapper .card.open {
        border: 1px solid rgba(255, 255, 255, .5);
    }
    .restaurants .container .restaurants_wrapper .card {
        padding: 32px;
        width: 100%;
        height: auto;
    }
    .restaurants .container .restaurants_wrapper .card > div {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        gap: 32px;
    }
    .restaurants .container .restaurants_wrapper .card .title {
        transform: none;
        white-space: nowrap;
    }
    .restaurants .container .restaurants_wrapper .card:not(.open) {
        width: 100%;
    }
}

@media (max-width: 575.98px) {
    .restaurants {
        padding: 40px 0;
    }
    .restaurants .container > span {
        font-size: 16px;
        line-height: 30px;
    }
    .restaurants .container > h1 {
        font-size: 19px;
        margin-bottom: 20px;
        line-height: 33px;
    }
    
}

.sponsors_section {
    padding: 100px 0;
    background-color: #060606;
}

.sponsors_section .container span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #AFB1BB;
    margin-bottom: 16px;
    display: block;
    margin: auto;
}

.sponsors_section .container h1 {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 65px;
    text-align: center;
    letter-spacing: -2px;
    color: #FFCA00;
}
.sponsors_section .container p {
    max-width: 600px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* or 162% */
    text-align: center;

    color: rgba(255, 255, 255, 0.64);
    display: block;
    margin: auto;
    margin-bottom: 64px;
}

.sponsors_section img {
    height: 130px;
    object-fit: contain;
    width: 155px;
}

.sponsors_section .sponsors_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 56px;
    max-width: 1000px;
    flex-wrap: wrap;
    margin: auto;
}

@media (max-width: 1199.98px) {
    .sponsors_section .container h1 {
        margin-bottom: 8px;
        font-style: normal;
        font-weight: 800;
        font-size: 50px;
        line-height: 65px;
        text-align: center;
        letter-spacing: -2px;
        color: #FFCA00;
    }
}

@media (max-width: 992.98px) {
    .sponsors_section .container h1 {
        margin-bottom: 8px;
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 55px;
        text-align: center;
        letter-spacing: -2px;
        color: #FFCA00;
    }
}

@media (max-width: 575.98px) {
    .sponsors_section {
        padding: 40px 0;
        background-color: #060606;
    }
    .sponsors_section .container h1 {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 55px;
        text-align: center;
        letter-spacing: -2px;
        color: #FFCA00;
    }
    .sponsors_section .container p {
        max-width: 600px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 25px;
        text-align: center;
        color: rgba(255, 255, 255, 0.64);
        display: block;
        margin: auto;
          margin-bottom: auto;
        margin-bottom: 44px;
    }
    .sponsors_section img {
        width: 115px;
    }
}

footer {
    background-color: #060606;
    color: #fff;
}

footer .subscribe_wrapper {
    position: relative;
    padding: 64px;
}
footer .subscribe_wrapper >*:not(img) {
    z-index: 2;
    position: relative;
}
footer .subscribe_wrapper .bg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(30%);
    position: absolute;
    top: 0;
    left: 0;
}

footer .subscribe_wrapper h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 57px;
    line-height: 88px;
    margin-bottom: 80px;
}

footer .subscribe_wrapper >div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
footer .subscribe_wrapper >div form p{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    
    margin-bottom: 8px;
}

footer .subscribe_wrapper >div form >div {
    display: flex;
    gap: 16px;
}
footer .subscribe_wrapper >div form input {
    padding: 16px;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    
    color: white;
}
footer .subscribe_wrapper >div form button {
    padding: 16px;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
    background-color: white;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    
    color: #2956F3;
    transition: .3s all ease-in;
}
footer .subscribe_wrapper >div form button:hover {
    background-color: #004DFF;
    border-color: #004DFF;
    color: #fff;
}
footer .subscribe_wrapper >div >div {
    align-items: center;
    display: flex;
    gap: 40px;
}
footer .subscribe_wrapper >div >div p {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    
}
footer .subscribe_wrapper >div >div a {
    padding: 16px;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
    background-color: white;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    line-height: 16.8px;
    
    color: #2956F3;
    transition: .3s all ease-in;
}
footer .subscribe_wrapper >div >div a:hover {
    background-color: #004DFF;
    border-color: #004DFF;
    color: #fff;
}

footer .links {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

footer .links >div >div:not(.social) {
    margin-bottom: 40px;
    display: flex;
    gap: 24px;
}
footer .links >div >div:not(.social) img {
    width: 120px;
}
footer .links >div .social {
    display: flex;
    gap: 16px;
}

footer .links >div .social img {
    width: 24px;
}

footer .links ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

footer .links ul h1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    
    margin-bottom: 16px;
}
footer .links ul a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    
}

footer .links .all_links {
    display: flex;
    gap: 80px;
}

footer .copy {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 56px 0;
}

footer .copy img {
    width: 165px
}
footer .copy p {
font-size: 14px;
font-weight: 400;
line-height: 16.8px;


}

@media (max-width: 1199.98px) {
    footer .subscribe_wrapper {
        position: relative;
        padding: 40px;
    }
    footer .subscribe_wrapper {
        position: relative;
        padding: 40px;
    }
    footer .subscribe_wrapper > div form p {
        font-size: 13px;
        font-weight: 600;
        line-height: 24px;
        
        margin-bottom: 8px;
    }
    footer .subscribe_wrapper > div form p {
        font-size: 13px;
        font-weight: 600;
        line-height: 24px;
        
        margin-bottom: 8px;
    } 
    footer .subscribe_wrapper > div > div p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21.6px;
        
    }
    footer .subscribe_wrapper h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 78px;
        margin-bottom: 50px;
    }
}

@media (max-width: 992.98px) {
    footer .subscribe_wrapper > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        align-items: start;
        gap: 40px;
    }
    footer .subscribe_wrapper > div form  {
        width: 100%
    }
    footer .subscribe_wrapper > div form > div {
        display: flex;
        gap: 16px;
        width: 100%;
        justify-content: space-between;
    }
    footer .subscribe_wrapper > div form button {
        padding: 16px;
        border: 1px solid #FFFFFF;
        border-radius: 2px;
        background-color: white;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        
        color: #2956F3;
        transition: .3s all ease-in;
        width: 180px;
        text-align: center;
    }
    footer .subscribe_wrapper > div > div {
        align-items: center;
        display: flex;
        gap: 40px;
        justify-content: space-between;
        width: 100%;
    }
    footer .subscribe_wrapper > div > div a {
        padding: 16px;
        border: 1px solid #FFFFFF;
        border-radius: 2px;
        background-color: white;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        line-height: 16.8px;
        
        color: #2956F3;
        transition: .3s all ease-in;
        width: 180px;
        text-align: center;
    }
    footer .links .all_links {
        display: flex;
        gap: 40px;
    }
}

@media (max-width: 767.98px) {
    footer .subscribe_wrapper {
        position: relative;
        padding: 32px;
    }
    footer .subscribe_wrapper h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 58px;
        margin-bottom: 20px;
    }
    footer .subscribe_wrapper > div form input {
        width: 100%;
    }
    footer .subscribe_wrapper > div form button {
        min-width: 180px;
    }
    footer .subscribe_wrapper > div > div {
        align-items: center;
        display: flex;
        gap: 16px;
        justify-content: space-between;
        width: 100%;
    }
    footer .links {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: start;
        flex-direction: column;
        gap: 24px;
    }
}

@media (max-width: 575.98px) {
    footer .subscribe_wrapper {
        position: relative;
        padding: 32px;
    }
    footer .subscribe_wrapper h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 20px;
    }
    footer .subscribe_wrapper > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        align-items: start;
        gap: 40px;
    }
    footer .subscribe_wrapper > div form > div {
        display: flex;
        gap: 16px;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
    }
    footer .subscribe_wrapper > div form button {
        min-width: 100%;
    }
    footer .subscribe_wrapper > div > div {
        align-items: center;
        display: flex;
        gap: 16px;
        justify-content: space-between;
        width: 100%;
        flex-direction: column;
    }
    footer .subscribe_wrapper > div > div a {
        width: 100%;
    }
    footer .links {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: start;
        flex-direction: column;
        gap: 24px;
    }
    footer .links > div  {
        width: 100%;
    }
    footer .links > div > div:not(.social) {
        margin-bottom: 40px;
        display: flex;
        gap: 24px;
        margin: auto;
          margin-bottom: auto;
        margin-bottom: 24px;
        width: max-content;
    }
    footer .links > div .social {
        display: flex;
        gap: 16px;
        justify-content: center;
    }
    footer .links .all_links {
        display: flex;
        flex-direction: column-reverse;
        gap: 32px;
        align-items: start;
        justify-content: space-between;
    }
    footer .links ul h1 {
        font-size: 16px;
        font-weight: 600;
        line-height: 21.6px;
        
        margin-bottom: 16px;
    }
    footer .links ul a {
        color: #fff;
        text-decoration: none;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        
    }
    footer .copy {
        display: flex;
        justify-content: space-between;
        align-items: end;
        padding: 24px 0;
        gap: 24px;
    }
    footer .copy p {
        font-size: 11px;
        font-weight: 400;
        line-height: 16.8px;
        
    }
    footer .copy img {
        width: 95px;
    }
    footer .subscribe_wrapper > div > div p {
        text-align: center;
    }
}

.events_schedual .container .content .body .card .text .category a img{
    width: 40px;
    height: 40px;
}


.more {
    list-style: none;
    position: absolute;
    top: calc(100% + 40px);
    width: 240px;
    right: 0;
    text-align: left;
    padding: 16px;
    background: white;
    border-radius: 3px;
    display: none;
    opacity: 0;
    text-transform: capitalize;
}

.more.open {
    display: block;
    animation: fadeIn 0.2s ease-in 0.2s forwards;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.more a {
    color: #080114;
    text-decoration: none;
    padding: 4px;
    transition: all .3s ease-in;
}

.more a:hover {
    color: #2956F3;
}

.ar .more {
    left: 0;
    right: auto;
    text-align: right;
}

.search_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    padding: 2rem;
    width: 100%;
    height: 100vh;
    background: #060606d1;
}

.search_wrapper form {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    position: relative;
}

.search_wrapper form input {
    width: 100%;
    background: transparent;
    border: none;
    border-bottom: 2px solid #FFFFFF52;
    color: white;
    padding: 12px 40px;
}
.search_wrapper form input:focus {
    outline: none;
    border-bottom: 2px solid #e6f60073;
}

.search_wrapper form >svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.search_wrapper form button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 0;
    background: #262626;
    color: white;
    border: none;
    gap: 8px;
    min-width: 120px;
}
.search_wrapper form button.search {
    background-color: #485FEB;
}

.suggestion {
    padding: 16px;
    overflow: auto;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    background: #ffffff0a;
    margin-top: 16px;
}

.suggestion a {
    color: #fff;
    text-decoration: none;
    padding: 8px;
    transition: all .3s ease-in;
}

.suggestion a:hover {
    color: #5E78EE;
}

@media (max-width: 575.98px) {
    .search_wrapper form input {
        width: 100%;
        background: transparent;
        border: none;
          border-bottom-width: medium;
          border-bottom-style: none;
          border-bottom-color: currentcolor;
        border-bottom: 2px solid #FFFFFF52;
        color: white;
        padding: 8px 32px;
    }
    .search_wrapper form button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;
        border-radius: 0;
        background: #262626;
          background-color: rgb(38, 38, 38);
        color: white;
        border: none;
        gap: 8px;
        min-width: 70px;
        font-size: 11px;
    }
    .search_wrapper {
        position: fixed;
        top: 0;
        left: 0;
        padding: 1rem;
        width: 100%;
        height: 100vh;
        background: #060606d1;
    }
}